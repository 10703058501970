function AuthApiService({makeRequest, buildRequest}) {

    function postAuth(username, userpassword) {
        return buildRequest()
            .then(function(req) {
                return makeRequest(req.post('api/login', { username, userpassword }));
            });
    }

    function getRefreshToken() {
        return buildRequest()
            .then(function(req) {
                return makeRequest(req.get('api/refresh/token'));
            });
    }

    return {
        postAuth,
        getRefreshToken
    };
};

export default AuthApiService;